@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center h-12 w-12 mt-2 mb-2 mx-auto shadow-lg bg-gray-800 text-purple-500
    hover:bg-purple-500 hover:text-white rounded-3xl hover:rounded-xl transition-all ease-in-out duration-300;
  }

  .active-icon {
    @apply rounded-xl bg-purple-500 text-white;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-left;
  }

  .header {
    @apply text-center shadow-sm shadow-black p-4 bg-gray-800
  }

  .main {
    @apply text-center text-white flex flex-col items-center h-screen p-8 ml-8
  }

  .purple-btn {
    @apply bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full shadow-md
  }

  .cell {
    @apply table-cell px-4 py-2
  }

  body {
    @apply bg-gray-700;
  }

  .link {
    @apply bg-purple-500 hover:bg-purple-700 rounded px-4 py-2 text-xl;
  }

  .control-group {
    @apply flex flex-col items-center mb-3;
  }

  .form-control {
    @apply w-full bg-gray-800 text-white rounded-lg px-4 py-2 border border-gray-700 focus:outline-none focus:border-purple-500;
  }

  .form-label {
    @apply text-lg font-bold mb-2;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
